<template>
  <div
    class="flex bg-sand"
    @mouseleave="currentImage = defaultImage"
  >
    <ul class="lg grid auto-rows-fr divide-y divide-black/10 px-2.5 py-1 lg:px-14 lg:py-7">
      <li
        v-for="ambition in ambitions"
        :key="ambition"
        @mouseenter="currentImage = (ambition.active_image ? ambition.active_image.url : null)"
      >
        <AmbitionTile
          :class="`max-lg:text-${ambition.color} lg:hover:text-${ambition.color}`"
          :title="ambition.title"
          :description="ambition.description"
          :to="localePath({name: 'ambitions-ambition', params: {ambition: ambition.slug}})"
        >
          <div class="inline-flex items-center justify-center p-0">
            <AmbitionIcon
              :ambition="ambition"
              class="size-14"
            />
          </div>
        </AmbitionTile>
      </li>
    </ul>

    <div class="min-h-[506px] flex-1 max-lg:hidden">
      <TransitionsFade>
        <img
          :key="currentImage ?? defaultImage"
          class="image aspect-2 h-full w-auto object-cover transition-all"
          :src="currentImage ?? defaultImage"
        >
      </TransitionsFade>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {AmbitionsQuery} from '~/graphql/graphql';

defineProps<{
  ambitions: AmbitionsQuery['ambitions'],
}>();

const localePath = useLocalePath();

const defaultImage = '/images/ambitions/block/default.png';
const currentImage = ref(null);
</script>

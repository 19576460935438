<template>
  <div class="relative">
    <img
      src="/images/hero2.png"
      alt="Hero section image"
    >
    <span
      v-for="point in content.points"
      :key="point.title"
      :style="{left: point.left + '%', top: point.top + '%'}"
      class="ball absolute h-5 w-5 origin-top-left animate-heart-beat rounded-full bg-green outline outline-[8px] outline-white hover:opacity-0"
      @mouseenter="currentPoint = point"
      @mouseleave="currentPoint = undefined"
    />
    <div class="pointer-events-none absolute inset-0">
      <TransitionsFade>
        <Ball
          v-if="currentPoint"
          :key="content"
          class="ball h-[380px] w-[380px] p-4 text-white"
          :style="{left: currentPoint.left_open + '%', top: currentPoint.top_open + '%'}"
        >
          <span class="mb-5 text-3xl font-bold">{{ currentPoint.title }}</span>
          <SofieText
            class="max-lg:hidden lg:pt-2.5"
            :value="currentPoint.description"
          />
        </Ball>
      </TransitionsFade>
    </div>
  </div>
</template>

<script setup lang="ts">
type Point = {
  top: number
  left: number
  top_open: number
  left_open: number
}

withDefaults(defineProps<{
  content?: Object,
}>(), {
  content: Object,
});

const currentPoint = ref<Point>();
</script>

<style>
.ball {
  @apply -translate-x-1/2 -translate-y-1/2 duration-200 transition-opacity;
}
</style>
